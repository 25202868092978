import { ApiPaymentsService } from '@/api/payments.service';

const state = () => ({
  product_id: null,
  cart_payed: false
});

const getters = {
  hasProductSelected: state => !!state.product_id,
  productData: (state, getters, rootState, rootGetters) => {
    const getterProductId = rootGetters['productsStore/getProductById'];
    return getterProductId(state.product_id);
  }
};

const actions = {
  async checkout({ state }, { link_to_promote, user_email, token_id }) {
    const formcheckout = {
      user_email,
      user_language: localStorage.getItem('boomfy_locale'),
      link_to_promote,
      product_id: state.product_id,
      payment_method_id: token_id
    };
    const resultPayment = await ApiPaymentsService.payProduct(formcheckout);
    return resultPayment.result;
  },

  async verification({ state }, { link_to_promote, user_email, token_id }) {
    const formCheckout = {
      user_email,
      user_language: localStorage.getItem('boomfy_locale'),
      link_to_promote,
      product_id: state.product_id,
      payment_method_id: token_id
    };
    const resultVerification = await ApiPaymentsService.verificationPurchase(formCheckout);
    return resultVerification.result;
  }
};

const mutations = {
  setProductId(state, product_id) {
    state.product_id = product_id;
  },
  setCartPayed(state, show = true) {
    state.cart_payed = show;
    // setTimeout(() => {
    //   state.cart_payed = false;
    // }, 5000);
  }
};

export const cartStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
