<template lang="pug">
navBar
router-view
navBottom
</template>

<script>
import navBar from "@/components/structure/navBar";
import navBottom from "@/components/structure/navBottom";

export default {
  components: {
    navBar,
    navBottom,
  },
  created() {
    this.$store.dispatch("productsStore/getProducts");
  },
};
</script>

<style lang="scss">
</style>
