import { createStore } from 'vuex';
import { productsStore } from './products.module';
import { cartStore } from './cart.module';

export default createStore({
  modules: {
    productsStore,
    cartStore
  }
});
