<template lang="pug">
.block-selector
  .box-selected(@click="show = !show", :class="{ active: show }")
    .lang-selected {{ selectedLang }}
    svgIcon(
      :path="mdiChevronUp",
      type="mdi",
      :size="18",
      :class="[show ? 'xyz-in' : 'xyz-out']",
      xyz="rotate-left-50% ease-out-back"
    )
    XyzTransition(appear, xyz="fade down ease-out-back")
      .list-languages(v-if="show")
        ul
          li(@click="setLanguage('de')") {{ $t('languages.de') }}
          li(@click="setLanguage('en')") {{ $t('languages.en') }}
          li(@click="setLanguage('es')") {{ $t('languages.es') }}
          li(@click="setLanguage('it')") {{ $t('languages.it') }}
          li(@click="setLanguage('fr')") {{ $t('languages.fr') }}
          li(@click="setLanguage('pl')") {{ $t('languages.pl') }}
          li(@click="setLanguage('pt')") {{ $t('languages.pt') }}
</template>
<script>
import { mdiChevronUp } from "@mdi/js";
import { loadLocaleMessages } from "@/plugins/i18n";
export default {
  name: "selector-box",
  data: () => ({
    mdiChevronUp,
    show: false,
    selectedLang: "Español",
  }),
  created() {
    this.SelectedLangOperation();
  },
  methods: {
    async setLanguage(lang) {
      await loadLocaleMessages(lang);
      this.SelectedLangOperation();
    },
    SelectedLangOperation() {
      const selected = localStorage.getItem("boomfy_locale");
      switch (selected) {
        case "de":
          this.selectedLang = this.$t("languages.de");
          break;
        case "en":
          this.selectedLang = this.$t("languages.en");
          break;
        case "es":
          this.selectedLang = this.$t("languages.es");
          break;
        case "it":
          this.selectedLang = this.$t("languages.it");
          break;
        case "fr":
          this.selectedLang = this.$t("languages.fr");
          break;
        case "pl":
          this.selectedLang = this.$t("languages.pl");
          break;
        case "pt":
          this.selectedLang = this.$t("languages.pt");
          break;
        default:
          this.selectedLang = this.$t("languages.en");
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.block-selector {
  @apply flex justify-center;
}
.box-selected {
  max-width: 280px;
  @apply bg-blue-700 w-full rounded-md py-2 px-4 font-bold transition-colors flex justify-between items-center cursor-pointer relative;

  &:hover,
  &.active {
    @apply bg-blue-600;
  }
}

.list-languages {
  position: absolute;
  width: 100%;
  max-height: 280px;
  bottom: 3rem;
  left: 0;
  @apply bg-white rounded-md shadow-2xl overflow-y-auto;

  li {
    @apply text-sm text-gray-500 py-3 px-4;
    + li {
      @apply border-t border-gray-100;
    }

    &:hover {
      @apply bg-gray-100;
    }
  }
}

@screen phone {
  .block-selector {
    @apply justify-start;
  }
}
</style>