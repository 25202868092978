<template lang="pug">
.pb-40.text-center
  h1 {{ $t('home.top-prices.h1') }}
  router-link.link(:to="{ name: 'PlansPage' }") {{ $t('home.top-prices.subtitle') }}
  .flex.flex-wrap.gap-8.mx-6.justify-center.mt-16
    CardPrice(
      v-for="product in topPrices",
      :key="product._id",
      :product="product",
      v-on:product-selected="setProductSelected"
    ) 
</template>

<script>
import { mdiInstagram, mdiCustomTikTok } from "@/plugins/iconFinder";
import CardPrice from "@/components/cards/card.prices";

export default {
  name: "section-precios-destacados",
  components: {
    CardPrice,
  },
  data: () => ({
    mdiInstagram,
    mdiCustomTikTok,
  }),
  methods: {
    async setProductSelected(product_id) {
      await this.$router.push({ name: "PlansPage" });
      setTimeout(() => {
        this.$store.commit("cartStore/setProductId", product_id);
      }, 600);
    },
  },
  computed: {
    topPrices() {
      return this.$store.getters["productsStore/getProductTops"];
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.8rem;
  @apply font-bold leading-tight mb-2;
}

.link {
  @apply text-blue-500 font-semibold text-base relative cursor-pointer transition-all;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -1px;
    transform: scaleX(0);
    transform-origin: center;
    @apply bg-blue-600;
    transition-duration: 0.25s;
  }
  &:hover {
    &::before {
      transform: scaleX(1);
    }
  }
}
</style>
