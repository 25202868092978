<template lang="pug">
.xyz-in.fixed.w-full.top-0.z-10(xyz="fade delay-2 up")
  .nav-bar(:class="{ scrolled: scrolled }")
    .container.h-full
      .nav
        router-link(:to="{ name: 'Home' }")
          LogoComponent
        .links-desktop
          router-link(:to="{ name: 'Home', hash: '#how-it-works' }") {{ $t('links.how') }}
          router-link(:to="{ name: 'PlansPage' }") {{ $t('links.price') }}
        .mobile-menu
          svgIcon(
            :path="mdiMenu",
            type="mdi",
            :size="24",
            @click="showMenuMobile = true"
          )
          teleport(to="body")
            XyzTransition(xyz="fade up-1")
              .modal-menu(v-if="showMenuMobile")
                .flex.p-4.flex-col.h-full.flex-shrink
                  svgIcon.text-white.self-end.cursor-pointer(
                    :path="mdiClose",
                    type="mdi",
                    :size="24",
                    @click="showMenuMobile = false"
                  )
                  .menu
                    a(
                      @click="$router.push({ name: 'Home', hash: '#how-it-works' }), (showMenuMobile = false)"
                    ) {{ $t('links.how') }}
                    a(
                      @click="$router.push({ name: 'PlansPage' }), (showMenuMobile = false)"
                    ) {{ $t('links.price') }}
                    a(
                      @click="$router.push({ name: 'ContactPage' }), (showMenuMobile = false)"
                    ) {{ $t('links.contact') }}
</template>

<script>
import LogoComponent from "@/components/structure/logo";
import { mdiMenu, mdiClose } from "@mdi/js";
export default {
  name: "navBar",
  data: () => ({
    scrolled: false,
    mdiMenu,
    mdiClose,
    showMenuMobile: false,
  }),
  components: {
    LogoComponent,
  },
  created() {
    window.addEventListener("scroll", this.listenerNavbar);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.listenerNavbar);
  },
  methods: {
    listenerNavbar() {
      const distanceTop = window.pageYOffset;
      if (distanceTop > 100) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  height: 75px;
  @apply transition-all;
  .nav {
    @apply w-full h-full flex justify-between items-center;
  }
}

.scrolled {
  height: 52px;
  @apply bg-blue-600 text-gray-50 shadow-md;

  :deep(.logo) {
    h1 {
      @apply text-white;
    }
  }

  .links-desktop {
    a {
      @apply text-white;
      &::before {
        @apply bg-blue-500;
      }
      &:hover {
        @apply text-white;
      }
    }
  }

  .mobile-menu {
    svg {
      @apply text-white;
    }
  }
}

.mobile-menu {
  svg {
    @apply text-blue-600 cursor-pointer;
  }
}
.modal-menu {
  position: fixed;
  top: 0;
  left: 0;
  @apply w-full h-full bg-blue-500 z-50;
  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    min-width: 140%;
    height: 74px;
    background-image: url("~@/assets/svg/wavesNegative.svg");
  }
  .menu {
    @apply flex h-full flex-col justify-center;

    a:first-of-type {
      margin-top: -3rem;
    }

    a {
      @apply text-white font-medium cursor-pointer;
    }
    a + a {
      margin-top: 2rem;
    }
  }
}

.links-desktop {
  display: none;
}
@screen sm {
  .mobile-menu,
  .modal-menu {
    display: none;
  }
  .links-desktop {
    display: block;
    a {
      @apply text-blue-600 relative cursor-pointer transition-all font-semibold text-sm;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -1px;
        transform: scaleX(0);
        transform-origin: left;
        @apply bg-blue-600;
        transition-duration: 0.25s;
      }

      &:hover {
        @apply text-blue-500;
        &::before {
          transform: scaleX(1);
        }
      }
    }

    a + a {
      margin-left: 2rem;
    }
  }
}
</style>
