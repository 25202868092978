<template lang="pug">
hero
ventajas
section3
sectionPreciosDestacados
teleport(to="head")
  title {{ $t('metas.home_title') }}
  meta(name="description", :content="$t('metas.home_title')")
</template>

<script>
// @ is an alias to /src
import hero from "@/components/home/hero";
import ventajas from "@/components/home/ventajas";
import section3 from "@/components/home/section3";
import sectionPreciosDestacados from "@/components/home/section-precios-destacados";

export default {
  name: "Home",
  components: {
    hero,
    ventajas,
    section3,
    sectionPreciosDestacados,
  },
};
</script>
