<template lang="pug">
.box-price-v2
  span {{ product.cuantity }}
  h2 {{ product.cuantity }}
  .flex.justify-center.items-center
    svgIcon.text-yellow-500(
      :path="platformIcon",
      :size="24",
      type="mdi",
      v-if="platformIcon"
    )
    p.pl-2.font-medium {{ productType }}
  .box-content.pt-4.px-4
    .flex.justify-between.mb-2
      p {{ $t('card_prices.platform') }}
      p.price {{ platformName }}
    .flex.justify-between.mb-2
      p {{ $t('card_prices.action') }}
      p.price {{ productType }}
    .flex.justify-between.mb-4
      p {{ $t('card_prices.price') }}
      p.price {{ price }}
    .button(@click="selectProduct") {{ $t('card_prices.button') }}
</template>
<script>
import { setPrice } from "@/plugins/numbers";
import { mdiInstagram, mdiCustomTikTok } from "@/plugins/iconFinder";
export default {
  name: "card.prices",
  props: ["product"],
  emits: ["product-selected"],
  data: () => ({ mdiInstagram, mdiCustomTikTok }),
  methods: {
    selectProduct() {
      this.$emit("product-selected", this.product._id);
    },
  },
  computed: {
    platformIcon() {
      switch (this.product.platform) {
        case "tiktok":
          return mdiCustomTikTok;
        case "instagram":
          return mdiInstagram;
        default:
          return null;
      }
    },
    platformName() {
      switch (this.product.platform) {
        case "tiktok":
          return "TikTok";
        case "instagram":
          return "Instagram";
        default:
          return this.product.platform;
      }
    },
    productType() {
      switch (this.product.type_product) {
        case "followers":
          return this.$t("followers");
        case "likes":
          return this.$t("likes");
        case "views":
          return this.$t("views");
        default:
          return this.product.type_product;
      }
    },
    price() {
      return setPrice(this.product.price, this.product.sign);
    },
  },
};
</script>
<style lang="scss" scoped>
.box-price-v2 {
  --tw-shadow: 0 5px 25px -2px rgba(0, 17, 255, 0.1),
    0 0px 10px -2px rgba(0, 132, 255, 0.04);
  box-shadow: var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  @apply relative p-2 py-6 rounded-xl w-60 max-w-xs flex-grow overflow-hidden;

  span {
    position: absolute;
    top: -30px;
    font-size: 8rem;
    line-height: 1;
    @apply font-extrabold;
    z-index: -1;
    opacity: 0.1;
    left: 0;
    right: 0;
    text-align: center;
  }

  h2 {
    @apply font-extrabold text-gray-500 text-5xl mb-4;
  }

  .box-content {
    p {
      @apply text-sm;
      &.price {
        @apply font-medium;
      }
    }
  }
  .button {
    @apply mt-2 w-full py-2 bg-blue-500 text-white rounded-md font-semibold cursor-pointer;
  }
}
</style>