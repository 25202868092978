<template lang="pug">
.container
  .dots
  .relative
    .hero
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos1.text-red-500(
          :size="20",
          :path="mdiHeart",
          xyz="fade small ease-out-back delay-3"
        )
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos2.text-blue-500(
          :size="42",
          :path="mdiFacebook",
          xyz="fade small ease-out-back delay-2"
        )
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos3.text-yellow-400(
          :size="18",
          :path="mdiHandPointingUp",
          xyz="fade small  ease-out-back"
        )
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos4.text-blue-300(
          :size="28",
          :path="mdiInstagram",
          xyz="fade small  ease-out-back delay-4"
        )
      .-mt-8
      H1Element {{ $t('home.h1') }}
      h2 {{ $t('home.h1_2') }}
        span.pl-4 {{ $t('home.h1_2_span') }}
      .mb-6
      p {{ $t('home.subtitle') }}
      p {{ $t('home.subtitle_2') }}
      .mb-12
      router-link(:to="{ name: 'PlansPage' }")
        .hero-button {{ $t('home.button') }}
      .group-active-users
        .box-users
          .user
            img(src="@/assets/img/person1.png")
          .user
            img(src="@/assets/img/person2.png")
          .user
            img(src="@/assets/img/person5.png")
          .user
            img(src="@/assets/img/person4.png")
          .user
            img(src="@/assets/img/person3.png")
        .influence-text {{ $t('home.subtitle_3') }}

      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos1-r.text-yellow-300(
          :size="20",
          :path="mdiEmoticonHappy",
          xyz="fade small ease-out-back delay-3"
        )
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos2-r.text-purple-500(
          :size="42",
          :path="mdiCustomTikTok",
          xyz="fade small ease-out-back delay-2"
        )
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos3-r.text-red-500(
          :size="18",
          :path="mdiYoutube",
          xyz="fade small  ease-out-back"
        )
      XyzTransition(appear, duration="auto")
        HeroIcon.hero-icon.pos4-r.text-indigo-400(
          :size="28",
          :path="mdiAirplane",
          xyz="fade small  ease-out-back delay-4"
        )
</template>

<script>
import HeroIcon from "@/components/home/hero-icon";
import H1Element from "@/components/elements/h1.element";
import {
  mdiHeart,
  mdiFacebook,
  mdiHandPointingUp,
  mdiYoutube,
  mdiInstagram,
  mdiEmoticonHappy,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiCustomTikTok,
} from "@/plugins/iconFinder";
export default {
  name: "hero",
  data: () => ({
    mdiAirplane,
    mdiAirplaneLanding,
    mdiHeart,
    mdiFacebook,
    mdiHandPointingUp,
    mdiYoutube,
    mdiInstagram,
    mdiEmoticonHappy,
    mdiCustomTikTok,
  }),
  components: {
    HeroIcon,
    H1Element,
  },
};
</script>

<style lang="scss" scoped>
.dots {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    right: -300px;
    top: 100px;
    background-image: url("~@/assets/svg/dots.svg");
    width: 400px;
    height: 400px;
    opacity: 0.05;
  }
  &::after {
    content: "";
    position: absolute;
    left: -300px;
    bottom: 100px;
    background-image: url("~@/assets/svg/dots.svg");
    width: 400px;
    height: 400px;
    opacity: 0.05;
  }
}

@screen sm {
  .dots {
    &::before {
      right: -100px;
    }
    &::after {
      left: -100px;
    }
  }
}

.hero {
  @apply w-full h-full min-h-screen flex justify-center items-center text-center flex-col;

  .hero-button {
    box-shadow: 0 0px 32px -8px rgba(59, 130, 246, 0.5),
      0 10px 10px -8px rgba(59, 130, 246, 0.8);

    transition: all 0.25s ease-in-out;
    @apply bg-blue-500 text-white font-semibold rounded-2xl py-3 px-6;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 0px 32px -8px rgba(59, 130, 246, 0.5),
        0 10px 10px -2px rgba(59, 130, 246, 0.6);
    }
  }

  h2 {
    font-size: 2rem;
    @apply font-bold leading-tight text-gray-700 text-center;
    z-index: 1;
  }

  h2 span {
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 16px;
      bottom: 4px;
      left: 0.5rem;
      z-index: -1;
      border-radius: 100px;
      @apply bg-green-300;
    }
  }

  p {
    @apply text-lg font-medium text-gray-500;
    z-index: 1;
  }
}
.hero-icon {
  position: absolute;
  display: none;
  &.pos1 {
    margin-top: -18rem;
    left: 6rem;
  }
  &.pos2 {
    margin-top: -8rem;
    left: -2rem;
  }
  &.pos3 {
    margin-top: 6rem;
    left: 8rem;
  }
  &.pos4 {
    margin-top: 18rem;
    left: 0rem;
  }

  &.pos1-r {
    margin-top: -18rem;
    right: 6rem;
  }
  &.pos2-r {
    margin-top: -8rem;
    right: -2rem;
  }
  &.pos3-r {
    margin-top: 6rem;
    right: 8rem;
  }
  &.pos4-r {
    margin-top: 18rem;
    right: 0rem;
  }
}

@screen sm {
  .hero h2 {
    font-size: 2.5rem;
  }
  .hero-icon {
    display: block;
  }
}

.group-active-users {
  @apply flex flex-col items-center mt-12;
  .influence-text {
    @apply text-sm font-medium text-gray-500;
  }
}
.box-users {
  @apply flex mb-4;
  .user {
    @apply w-9 h-9 overflow-hidden rounded-full border-2 border-green-500;
    & + .user {
      @apply -ml-3;
    }
  }
}
</style>
