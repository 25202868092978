import axios from 'axios';

const RESOURCE = 'payments';

export const ApiPaymentsService = {
  async payProduct(formPayment) {
    const { data } = await axios.post(RESOURCE, formPayment);
    return { result: data };
  },
  async verificationPurchase(formPayment) {
    const { data } = await axios.put(RESOURCE, formPayment);
    return { result: data };
  }
};
