<template lang="pug">
.container.mb-40.relative
  .circle
  .py-10
    .grid-box
      ventajasBox(
        color="text-green-500",
        :icon="mdiChartTimelineVariantShimmer",
        :title="$t('home.destacado.title_1')",
        :message="$t('home.destacado.msg_1')"
      )
      ventajasBox(
        color="text-red-500",
        :title="$t('home.destacado.title_2')",
        :icon="mdiCharity",
        :message="$t('home.destacado.msg_2')"
      )
      ventajasBox(
        color="text-purple-500",
        :title="$t('home.destacado.title_3')",
        :icon="mdiTrophyAward",
        :message="$t('home.destacado.msg_3')"
      )
  svgIcon.triangle(:path="mdiDotsTriangle", type="mdi", :size="250")
</template>

<script>
import ventajasBox from "./ventajas-box";
import {
  mdiDotsTriangle,
  mdiChartTimelineVariantShimmer,
  mdiCharity,
  mdiTrophyAward,
} from "@mdi/js";
export default {
  name: "ventajas",
  components: {
    ventajasBox,
  },
  data: () => ({
    mdiDotsTriangle,
    mdiChartTimelineVariantShimmer,
    mdiCharity,
    mdiTrophyAward,
  }),
};
</script>

<style lang="scss" scoped>
.circle {
  width: 200px;
  height: 200px;
  border-width: 25px;
  position: absolute;
  z-index: -1;
  top: -50px;
  left: -100px;
  @apply border-blue-50 rounded-full;
}

.triangle {
  position: absolute;
  right: -120px;
  transform: rotate(120deg);
  bottom: -100px;
  z-index: -1;
  @apply text-red-50;
}

.grid-box {
  @apply grid grid-cols-1 gap-8;
}

@screen sm {
  .grid-box {
    @apply grid-cols-3;
  }
}
</style>
