<template lang="pug">
XyzTransition(
  :appear-visible="{ threshold: 0.5, rootMargin: '50px' }",
  duration="auto",
  mode="in-out",
  xyz="fade delay-2 up stagger"
)
  .ventaja-card
    svgIcon.iocon(:path="icon", type="mdi", :size="24", :class="color")
    h1 {{ title }}
    p {{ message }}
</template>

<script>
export default {
  name: "ventajas-box",
  props: {
    title: String,
    message: String,
    icon: String,
    color: String,
  },
};
</script>

<style lang="scss" scoped>
.ventaja-card {
  --tw-shadow: 0 5px 25px -2px rgba(0, 17, 255, 0.1),
    0 0px 10px -2px rgba(0, 132, 255, 0.04);
  box-shadow: var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  @apply bg-white py-6 px-4 rounded-xl transition-transform ease-in-out duration-1000 relative overflow-hidden;

  &:hover {
    @apply transform -translate-y-2;
  }

  h1 {
    @apply text-xl font-bold my-4;
  }

  p {
    @apply text-sm;
  }

  .iocon {
    position: absolute;
    transform: rotate(-25deg);
    opacity: 0.1;
    width: 120px;
    height: 120px;
    top: -25px;
    left: -25px;
  }
}
</style>
