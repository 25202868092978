import { createRouter, createWebHistory } from 'vue-router';
import { trackRouter } from 'vue-gtag-next';

import Home from '../views/Home.vue';

const PlansPage = () => import('../views/plans.vue');

const ContactPage = () => import('../views/pages/contact.vue');
const CookiesPage = () => import('../views/pages/cookies.vue');
const PrivacyPage = () => import('../views/pages/privacy.vue');
const TermsPage = () => import('../views/pages/terms.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/plans',
    name: 'PlansPage',
    component: PlansPage,
    props: route => ({ filter: route.query.filter, platform: route.query.platform })
  },

  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/cookies',
    name: 'CookiesPage',
    component: CookiesPage
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: PrivacyPage
  },
  {
    path: '/terms',
    name: 'TermsPage',
    component: TermsPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 150,
        behavior: 'smooth'
      };
    } else {
      return {
        top: 0,
        behavior: 'smooth'
      };
    }
  }
});

trackRouter(router);

export default router;
