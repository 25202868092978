<template lang="pug">
#how-it-works.container.mb-40
  .grid-how-it-works
    .text-box
      h1 {{ $t('home.how-work.h1') }}<br> {{ $t('home.how-work.h1_2') }}
      p {{ $t('home.how-work.text_1') }}
      p {{ $t('home.how-work.text_2') }}

      ul
        li
          svgIcon.text-green-500.mr-2.flex-shrink-0(
            :path="mdiArrowRight",
            type="mdi",
            :size="24"
          ) 
          | {{ $t('home.how-work.step_1') }}
        li
          svgIcon.text-green-500.mr-2.flex-shrink-0(
            :path="mdiArrowRight",
            type="mdi",
            :size="24"
          ) 
          | {{ $t('home.how-work.step_2') }}
        li
          svgIcon.text-green-500.mr-2.flex-shrink-0(
            :path="mdiArrowRight",
            type="mdi",
            :size="24"
          ) 
          | {{ $t('home.how-work.step_3') }}
        li 
          svgIcon.text-green-500.mr-2.flex-shrink-0(
            :path="mdiArrowRight",
            type="mdi",
            :size="24"
          ) 
          | {{ $t('home.how-work.step_4') }}

      .grid.grid-cols-2.gap-4.mt-6
        router-link.button-inst(
          :to="{ name: 'PlansPage', query: { platform: 'instagram' } }"
        )
          span.font-bold.text-white Instagram
        router-link.button-tt(:to="{ name: 'PlansPage', query: { platform: 'tiktok' } }")
          span.font-bold.text-white TikTok

    .image-box
      img(src="~@/assets/svg/social.svg") 
</template>

<script>
import { mdiArrowRight } from "@/plugins/iconFinder";
export default {
  name: "section3",
  data: () => ({
    mdiArrowRight,
  }),
};
</script>

<style lang="scss" scoped>
.button-tt,
.button-inst {
  @apply text-center p-3 rounded-2xl cursor-pointer relative overflow-hidden;
  &:hover {
    &::before {
      transform: translateX(-30%);
    }
  }
}

.button-inst::before,
.button-tt::before {
  content: "";
  width: 150%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: transform 0.25s ease-in-out;
  @apply bg-gradient-to-r from-yellow-500 via-red-500 to-pink-500;
}

.button-tt::before {
  @apply from-pink-500 via-purple-500 to-red-500;
}

.text-box {
  @apply flex flex-col justify-center;
  h1 {
    @apply text-3xl font-bold mb-4;
  }
  p {
    @apply font-medium mb-4;
  }

  ul li {
    @apply text-sm pb-2 flex items-center;
  }
}

.image-box {
  display: none;
  img {
    margin-left: 120px;
  }
}

.text-box {
  @apply col-span-2;
}

.grid-how-it-works {
  @apply grid grid-cols-2 gap-8;
}

@screen md {
  .text-box {
    @apply col-span-1;
  }
  .image-box {
    display: block;
    @apply flex flex-col justify-center items-center h-full;
  }
}
</style>
