<template lang="pug">
.icon
  svgIcon(:path="path", :size="size", type="mdi")
</template>

<script>
export default {
  name: "hero-icon",
  data: () => ({}),
  props: {
    size: Number,
    path: String,
  },
};
</script>

<style scoped lang="scss">
.icon {
  border-width: 10px;
  --tw-shadow: 0 20px 25px -8px rgba(0, 17, 255, 0.1),
    0 10px 10px -8px rgba(0, 132, 255, 0.04);
  box-shadow: var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  @apply p-2 rounded-2xl border-blue-50;
}
</style>
