import { ApiProductsService } from '@/api/products.service';

const state = () => ({
  listProducts: [],
  filter_platform: 'tiktok',
  filter_type: 'followers'
});

const getters = {
  getListProducts: state =>
    state.listProducts
      .filter(product => product.platform == state.filter_platform && product.type_product == state.filter_type)
      .sort((a, b) => a.cuantity - b.cuantity),
  getProductById: state => product_id => state.listProducts.find(product => product._id == product_id),
  getProductTops: state => state.listProducts.sort(() => Math.random() - 0.5).slice(0, 6)
};

const actions = {
  getProducts({ commit }) {
    ApiProductsService.getProducts().then(({ products }) => commit('setProducts', products));
  }
};

const mutations = {
  setProducts(state, products) {
    state.listProducts = products;
  },
  setFilterPlatform(state, platform) {
    state.filter_platform = platform;
  },
  setFilterTypeProduct(state, type) {
    state.filter_type = type;
  }
};

export const productsStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
