<template lang="pug">
h1
  slot
</template>
<script>
export default {
  name: "h1.element",
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  @apply font-bold leading-tight text-gray-700 text-center;
  z-index: 1;
}
@screen sm {
  h1 {
    font-size: 2.5rem;
  }
}
</style>