<template lang="pug">
.logo
  .rocket
  h1 boomfy.app
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.logo {
  @apply flex items-center;
  .rocket {
    background-image: url("~@/assets/svg/rocket.svg");
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 0.5rem 0 0;
  }
  h1 {
    @apply font-bold text-blue-600 text-lg;
  }
}
</style>