import App from './App.vue';
import { createApp } from 'vue';
import store from './store';
import router from './router';

/** Language */
import { setConfi18n } from './plugins/i18n';

/** Service Worker */
import './registerServiceWorker';

/** Styles */
import './style.tailwind.css';
import VueAnimXyz from '@animxyz/vue3';
import SvgIcon from '@jamescoyle/vue-icon';
import '@animxyz/core';
/** Styles */

/** Api axios conf */
import '@/plugins/axios';

//** analytics */
import VueGtag from 'vue-gtag-next';

const app = createApp(App);

app.component('svgIcon', SvgIcon);

app
  .use(store)
  .use(router)
  .use(VueAnimXyz)
  .use(VueGtag, {
    property: { id: 'UA-180732662-3' },
    router
  })
  .use(setConfi18n())
  .mount('#app');
