<template lang="pug">
footer.mt-0
  .container
    .footer-content
      .flex.flex-wrap.mb-12
        .logo-group
          .relative
            LogoComponent
            .plot.plot-1
            .plot.plot-2
            .plot.plot-4
            .plot.plot-5
          p.subtitle {{ $t('footer.title') }}
          SelectorLanguage.my-4
        .links-group
          ul.pages
            li.title {{ $t('footer.title_services') }}
            li 
              router-link(
                :to="{ name: 'PlansPage', query: { platform: 'tiktok', filter: 'followers' } }"
              ) {{ $t('followers') }} TikTok
            li 
              router-link(
                :to="{ name: 'PlansPage', query: { platform: 'tiktok', filter: 'likes' } }"
              ) {{ $t('likes') }} TikTok
            li 
              router-link(
                :to="{ name: 'PlansPage', query: { platform: 'tiktok', filter: 'views' } }"
              ) {{ $t('views') }} TikTok
            li 
              router-link(
                :to="{ name: 'PlansPage', query: { platform: 'instagram', filter: 'followers' } }"
              ) {{ $t('followers') }} Instagram
            li 
              router-link(
                :to="{ name: 'PlansPage', query: { platform: 'instagram', filter: 'likes' } }"
              ) {{ $t('likes') }} Instagram
          ul.pages
            li.title {{ $t('footer.title_pages') }}
            li 
              router-link(:to="{ name: 'Home' }") {{ $t('links.home') }}
            li 
              router-link(:to="{ name: 'Home', hash: '#how-it-works' }") {{ $t('links.how') }}
            li 
              router-link(:to="{ name: 'PlansPage' }") {{ $t('links.price') }}
            li 
              router-link(:to="{ name: 'ContactPage' }") {{ $t('links.contact') }}
      .box-links.flex.justify-end
        ul.flex.gap-8.links
          li 
            router-link(:to="{ name: 'PrivacyPage' }") {{ $t('links.privacy') }}
          li 
            router-link(:to="{ name: 'CookiesPage' }") {{ $t('links.cookies') }}
          li 
            router-link(:to="{ name: 'TermsPage' }") {{ $t('links.terms') }}
</template>

<script>
import LogoComponent from "@/components/structure/logo";
import SelectorLanguage from "@/components/selector-lang/selector-box";
export default {
  name: "navBottom",
  components: {
    LogoComponent,
    SelectorLanguage,
  },
};
</script>

<style lang="scss" scoped>
:deep(.logo) {
  h1 {
    @apply text-white text-2xl;
  }
}
footer {
  width: 100%;
  position: relative;
  @apply bg-blue-800 text-gray-50;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    min-width: 1600px;
    height: 74px;
    background-image: url("~@/assets/svg/wavesNegative.svg");
    transform: rotate(180deg);
  }
}
.container {
  padding: 0 2rem;
}

.footer-content {
  @apply pt-40 pb-4 relative;

  .subtitle {
    @apply text-sm text-blue-200 mt-0 pl-4;
  }
}
.logo-group {
  width: 100%;
  margin: 0 0 2rem 0;
}
.links-group {
  @apply flex gap-8 flex-wrap;
}

.pages {
  min-width: 200px;
  @apply text-base font-normal;

  li {
    @apply text-sm;
    &.title {
      @apply text-lg font-bold relative inline-block;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        width: 90%;
        height: 8px;
        left: -0.5rem;
        bottom: 4px;
        border-radius: 6px;
        z-index: -1;
        @apply bg-blue-500 opacity-40;
      }
    }
    & + li {
      margin-top: 1rem;
    }
    a {
      @apply text-blue-200 relative cursor-pointer;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -1px;
        transform: scaleX(0);
        transform-origin: left;
        @apply bg-blue-600 transition-all;
        transition-duration: 0.25s;
      }

      &:hover {
        @apply text-white;
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
}

.links {
  @apply text-xs font-normal text-blue-200;

  a:hover {
    @apply text-white;
  }
}

.plot {
  width: 4px;
  height: 4px;
  position: absolute;
  @apply rounded-full;
}

.plot-1 {
  left: -1px;
  bottom: 0;
  @apply bg-red-600;
}

.plot-2 {
  left: -3px;
  bottom: -6px;
  width: 5px;
  height: 5px;
  @apply bg-green-400;
}

.plot-4 {
  left: -12px;
  bottom: -5px;
  width: 8px;
  height: 8px;
  @apply bg-yellow-500;
}
.plot-5 {
  left: -12px;
  bottom: -16px;
  width: 10px;
  height: 10px;
  @apply bg-indigo-500;
}

@screen phone {
  .container {
    padding: 0 5rem;
  }
}

@screen md {
  .container {
    padding: 0 1rem;
  }
  .logo-group {
    width: auto;
  }
  .links-group {
    @apply ml-auto;
  }
}
</style>

