import axios from 'axios';

const RESOURCE = 'products';

export const ApiProductsService = {
  async getProducts() {
    const { data } = await axios.get(RESOURCE);
    return { products: data };
  }
};
